.footer {
  background: #fbfaf9;
  padding-top: 64px;
}

@media (max-width: 950px) {
  .footer {
    flex-direction: column;
    padding-top: 32px;
  }
}

.footer .blocks {
  max-width: 900px;
  flex-direction: row;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 950px) {
  .footer .blocks {
    flex-direction: column;
  }
}

.footer .blocks .block {
  flex: 1;
  margin: 32px;
  padding: 0;
}

@media (max-width: 950px) {
  .footer .blocks .block {
    margin: 24px;
  }
}

.footer .blocks .block h2 {
  color: #353535;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
}

@media (max-width: 950px) {
  .footer .blocks .block h2 {
    letter-spacing: 1.3px;
    font-size: 20px;
  }
}

.footer .blocks .block ul {
  margin: 16px 0 0;
  padding: 0 0 0 24px;
}

@media (max-width: 950px) {
  .footer .blocks .block ul {
    padding-left: 32px;
  }
}

.footer .blocks .block li {
  color: #616161;
  margin-bottom: 4px;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 950px) {
  .footer .blocks .block li {
    margin-bottom: 8px;
    font-size: 18px;
  }
}

.footer .blocks .block li::marker {
  color: #fbbf24;
}

.footer .blocks .block a {
  color: inherit;
  text-decoration: none;
}

.footer .blocks .block a:hover {
  color: #483400;
}

.footer .copyright {
  color: #616161;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  display: flex;
}

.footer .copyright img {
  margin-right: 32px;
}

@media (max-width: 950px) {
  .footer .copyright {
    padding: 24px;
  }
}

/*# sourceMappingURL=footer.c0087d20.css.map */
